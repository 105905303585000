import React from "react"
import Layout from '../components/Layout'
import Icon from '../components/Icon'
import { diamond_1, diamond_2, address_bg, qrcode, tel } from '../utils/constant'
import * as styles from './about.module.less'

const address = 'https://map.baidu.com/search/%E4%B8%8A%E6%B5%B7%E5%B8%82%E6%B5%A6%E4%B8%9C%E6%96%B0%E5%8C%BA%E4%B8%96%E7%BA%AA%E5%A4%A7%E9%81%931701%E5%8F%B7(%E4%B8%AD%E5%9B%BD%E9%92%BB%E7%9F%B3%E4%BA%A4%E6%98%93%E4%B8%AD%E5%BF%83)%20a%E6%A5%BC1002%E5%AE%A4/@13530193.845,3640348.78,19z?querytype=s&da_src=shareurl&wd=%E4%B8%8A%E6%B5%B7%E5%B8%82%E6%B5%A6%E4%B8%9C%E6%96%B0%E5%8C%BA%E4%B8%96%E7%BA%AA%E5%A4%A7%E9%81%931701%E5%8F%B7(%E4%B8%AD%E5%9B%BD%E9%92%BB%E7%9F%B3%E4%BA%A4%E6%98%93%E4%B8%AD%E5%BF%83)%20%EF%BC%A1%E6%A5%BC1002%E5%AE%A4&c=289&src=0&pn=0&sug=0&l=13&b=(13481423,3645962;13563343,3686122)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&seckey=1c7e316a4540e50ac95e9230bec47dbcb80e3ae5bb6a5e50a29d1f9face80bde809c0809b62dc348fb8e9375c542f12cea0f3973b2f8374a4ee078076449048d06e880b4262b2b8b6fc6d9b3cb00d4cd3037bc32e93f0e028a26e84acea337db9eb6345034303e38abf0bb76f9e328e5891681e8be34ce672fc988c1ab5acaf0ab5f0034aaa7eef71fb42ba6d58554d6003ba368a3a850fb1b491a7f6b75943b5aa1699f1673942a4e09d8c759087c7131f4530ef5b4b2175c3a34889fae42f5baebf7ba555130ec57062758f9f2b2f92529378ec598a6f2648c139b9dead69b6b6cd2cf2fc48180f5a35ecd1405c0eb&device_ratio=1'

const AboutUs = () => {
  return (
    <Layout
      contentClassName={styles.container}
      plainHeader
      seoProps={{ title: "关于宝猫" }}
    >
      <section className={styles.main}>
        <div className={styles.row}>
          <div className={styles.txtBox}>
            <h1 className={styles.title}>关于我们</h1>
            <p className={styles.txt}>
              RAPNETBUY（宝猫科技）是一站式B2B钻石交易服务平台, 为全球最大的钻石交易信息网站RAPNET中国区唯一官方合作伙伴，同时也是国家珠宝玉石质量监督检验管理中心(NGTC) 战略合作伙伴，致力于为中国机构买家提供便捷安全的B2B钻石交易和增值服务，使中国机构买家可以方便,安全,快捷地直采全球钻石商品。
            </p>
          </div>
          <div className={styles.imgBox}>
            <img className={styles.img} src={diamond_1} alt="钻石"/>
          </div>
        </div>
        <div className={styles.diveder} />
        <div className={styles.row}>
          <div className={styles.imgBox2}>
            <img className={styles.img} src={diamond_2} alt="钻石"/>
          </div>
          <div className={styles.infoBox}>
            <h1 className={styles.title}>联系我们</h1>
            <a href={address} className={styles.addressBox} target="_blank" rel="noreferrer">
              <div className={styles.address}>
                <div className={styles.label}>
                  <Icon type="iconMap" className={styles.labelIcon} />
                  <span>公司地址</span>
                </div>
                <p className={styles.val}>上海市浦东新区世纪大道1701号(中国钻石交易中心) Ａ楼1002室</p>
                <p className={styles.marginTop16}>深圳市罗湖区翠竹街道翠锦社区贝丽北路97号水贝银座大厦1801</p>
              </div>
              <img className={styles.addressImg} src={address_bg} alt="宝猫公司地址"/>
            </a>
            <div className={styles.linkBox}>
              <div>
                <div>
                  <div className={styles.label}>
                    <Icon type="iconphone" className={styles.labelIcon} />
                    <span>电话</span>
                  </div>
                  <p className={styles.val}>{tel}</p>
                </div>
                <div className={styles.infoDiveder} />
                <div>
                  <div className={styles.label}>
                    <Icon type="iconmail" className={styles.labelIcon} />
                    <span>邮箱</span>
                  </div>
                  <p className={styles.val}>service@rapnetbuy.com</p>
                </div>
              </div>
              <div>
                <div className={styles.label}>
                  <Icon type="iconweixin" className={styles.wechatIcon} />
                  <span>微信客服</span>
                </div>
                <img className={styles.qrcode} src={qrcode} alt="客服二维码"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutUs
