// extracted by mini-css-extract-plugin
export const container = "about-module--container--2kgn3";
export const main = "about-module--main--3sQWU";
export const row = "about-module--row--3U3jd";
export const txtBox = "about-module--txtBox--3CRLx";
export const imgBox = "about-module--imgBox--s5ehg";
export const infoBox = "about-module--infoBox--1cgn_";
export const imgBox2 = "about-module--imgBox2--3pHnJ";
export const title = "about-module--title--PVt7k";
export const txt = "about-module--txt--zHR-7";
export const img = "about-module--img--KKmNZ";
export const diveder = "about-module--diveder--BMMs1";
export const addressBox = "about-module--addressBox--1qXcB";
export const address = "about-module--address--SjnSp";
export const addressImg = "about-module--addressImg--1Mgpb";
export const label = "about-module--label--2mMAB";
export const val = "about-module--val--2Rn09";
export const marginTop16 = "about-module--marginTop16--18E_p";
export const labelIcon = "about-module--labelIcon--2PhYF";
export const wechatIcon = "about-module--wechatIcon--2ulMA";
export const linkBox = "about-module--linkBox--2jvyb";
export const qrcode = "about-module--qrcode--feB1h";
export const infoDiveder = "about-module--infoDiveder--2AiM7";